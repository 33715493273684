.nanopopup.login-form-default-popup {
  padding: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
  color: var(--landing-page-color1);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  a {
    color: var(--landing-page-heading-dark);
    &:hover {
      text-decoration: underline;
      color: var(--landing-page-color1);
    }
  }

  .admin-popup-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 110px;
    background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/adm-popup-header-wave.png");
    background-repeat: no-repeat;
    background-position: center -60px;
    z-index: 10;

    .close-container {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--landing-page-popup-close-bg);
      left: 10px;
      top: 10px;

      svg {
        font-size: 20px;
        color: var(--default-color-2);
      }

      &:hover {
        background-color: var(--landing-page-popup-close-bg-hover);
      }
    }

    .admin-popup-header-logo {
      flex: 1 0 30%;
      padding-left: 10%;
      margin-bottom: 10px;

      img {
        width: 80px;
      }
    }

    .admin-popup-header-products {
      display: flex;
      flex-direction: column;
      flex: 1 0 70%;
      align-items: center;

      .header-products-container {
        width: 50%;
        margin-top: 0;
        transform: translateY(40%);

        .header-products-links {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 5px;

          img {
            display: inline-block;
            max-width: 50%;
          }
        }
      }
    }

    .login-redirection-message {
      width: 100%;
      padding: 0 20px 40px 3em;
      color: var(--default-color-2);
      font-size: clamp(14px, 3vw, 30px);
      line-height: 1em;
      text-align: center;
    }
  }

  .admin-popup-login-form {
    margin: -40px 0 0;
    position: relative;
    padding: 60px 40px 10px;
    z-index: 20;

    .invalid-credential {
      color: var(--content-invalid);
      margin: 0 0 5px;
    }

    .create-account {
      text-align: center;
      margin-bottom: 20px;
    }

    .login-form-options {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 0 20px;

      .form-field-checkbox {
        flex: 1;

        label {
          color: var(--landing-page-heading-dark);
          font-size: clamp(12px, 2.5vw, 16px);
          font-weight: 700;
          line-height: 1em;
        }
      }

      .login-form-forgotpwd {
        flex: 0;
        white-space: nowrap;

        a {
          font-size: clamp(12px, 2.5vw, 16px);
        }
      }
    }

    .login-form-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      gap: 10px;

      .actions-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 1 100%;
        width: 100%;
        gap: 20px;

        .google-button {
          margin: 0 auto 20px;
          padding: 10px 0 0;
          text-align: center;
          max-width: 320px;

          .google-desc-text {
            padding: 0;
            p {
              padding: 0;
              margin: 0;
            }
            p:first-child {
              color: var(--default-color-3);
              font-size: 16px;
              font-weight: 700;
              line-height: 20px;
              margin-bottom: 5px;
            }
            p:last-child {
              color: var(--constellation-main);
              font-size: 11px;
              font-weight: 800;
              line-height: 12px;
              margin-bottom: 15px;
            }
            span {
              color: var(--troubadour-main);
            }
          }
        }

        @media screen and (min-width: 801px) {
          flex-direction: row;
          .google-button {
            text-align: left;
            margin-bottom: 0;
          }
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      aspect-ratio: 139 / 554;
      height: 100%;
      background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/adm-login-popup-bg-left.png");
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: cover;
      max-width: 150px;
      max-height: 392px;
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      aspect-ratio: 146 / 421;
      height: 75%;
      background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/adm-login-popup-bg-right.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: cover;
      max-width: 100px;
      max-height: 275px;
      z-index: -1;
    }
  }

  .nanomonx-form {
    input[type="checkbox"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 5px;
      padding: 5px;
      min-width: 25px;
      min-height: 25px;
      max-height: 25px;
      max-width: 25px;
      border: 2px solid var(--admin-main);
      transition: 0.2s all linear;
      border-radius: 4px;
      background-color: var(--default-bg);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1px;

      &:disabled {
        border-color: var(--border) !important;
      }

      &:focus {
        outline: none;
      }

      &:checked {
        &:after {
          content: "";
          transform: rotate(45deg);
          width: 5px;
          height: 10px;
          border-bottom: 3px solid var(--admin-main);
          border-right: 2px solid var(--admin-main);
        }
      }

      &:hover {
        cursor: pointer;
      }

      // All the label that are after a check box
      & ~ label {
        color: var(--default-color-3);
        font-size: 16px;
        padding: 6px;
        margin: 0;

        &:hover {
          cursor: pointer;
        }
      }

      &:disabled {
        cursor: unset !important;

        & ~ label {
          color: var(--primary-text-3);
          opacity: 0.8;
          cursor: unset !important;
        }
      }
    }
  }

  @media screen and (max-height: 799px) {
    .nanomonx-form {
      .form-field {
        margin-bottom: 0.5rem;
      }
    }
    .admin-popup-login-form {
      .create-account {
        margin-bottom: 10px;
      }

      .login-form-footer {
        .actions-group {
          gap: 10px;
        }
      }
    }
  }

  @media screen and (min-width: 801px) {
    font-size: 20px;
    line-height: 25px;

    .admin-popup-header {
      background-position: center center;
      min-height: 160px;

      .close-container {
        width: 50px;
        height: 50px;
        left: 15px;
        top: 15px;

        svg {
          font-size: 25px;
        }
      }

      .admin-popup-header-logo,
      .admin-popup-header-products {
        flex-basis: 50%;
      }
    }

    .admin-popup-header .admin-popup-header-logo img {
      width: 120px;
    }
    .admin-popup-header
      .admin-popup-header-products
      .header-products-container
      .header-products-title {
      font-size: 20px;
      line-height: 20px;
    }

    .admin-popup-header
      .admin-popup-header-products
      .header-products-container
      .header-products-links
      a {
      max-width: 75px;
    }

    .admin-popup-login-form {
      margin: -40px 0 0;
      padding: 80px 80px 40px;
    }

    .admin-popup-login-form {
      padding: 60px 100px 40px;

      &::before {
        max-width: 212px;
        max-height: 554px;
      }
      &::after {
        max-width: 153px;
        max-height: 360px;
      }
    }
  }
}

// nanopopup overrides
@media (min-width: 1px),
  (max-width: 1280px),
  (max-width: 992px),
  (max-width: 767px),
  (max-width: 480px) {
  .nanopopup {
    &.login-form-default-popup {
      height: auto !important;
      min-height: auto !important;
      max-height: auto !important;
      min-width: 320px !important;
      max-width: 726px !important;
    }
  }
}
